
import { defineComponent, onMounted, ref, Ref } from "vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

interface DetailsProps {
  details: {
    name: "";
    publicId: "";
    rates: {
      name: "";
      unit: "";
      value: "";
      publicId: "";
    }[];
    termsAndConditions: {
      package: {
        businessType: "";
        name: "";
        publicId: "";
        terms: "";
      };
    }[];
    packages: {
      loadingsAndDiscounts: {
        id: "";
        name: "";
        type: "";
        rateType: "";
        unit: "";
        value: "";
      }[];
    }[];
  }[];
}

export default defineComponent({
  name: "underwriter-terms-details-component",
  components: {},
  props: {
    details: {
      // type: Object as PropType<{ rates: RatesArray[] }>,
      type: Array as () => DetailsProps["details"],
      required: true,
    },
    underwriterPublicId: String,
  },
  setup(props) {
    const {
      capitalize,
      removeDashesFromUUID,
      useReusableNavigation,
      isEmptyArray,
    } = ResusableFunctions();
    const isExpanded = ref([]) as Ref<boolean[]>; // Use Ref to specify the type
    const generateTargetId = (publicId: string) => `#${publicId}`;

    const toggleCollapse = (index: number) => {
      isExpanded.value[index] = !isExpanded.value[index];
    };

    const editBusinessClass = (id) => {
      return useReusableNavigation(false, "admin-edit-underwriters-package", {
        publicId: id,
        underwriterId: props.underwriterPublicId,
      });
    };

    onMounted(() => {
      isExpanded.value = new Array(props.details.length).fill(false);
    });

    return {
      capitalize,
      isEmptyArray,
      removeDashesFromUUID,
      generateTargetId,
      isExpanded,
      toggleCollapse,
      editBusinessClass,
    };
  },
});
