
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { config } from "@/core/helpers/config";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ClientClaimsTable from "@/components/individual/ClientClaimsTable.vue";
import TermsDetails from "@/views/admin/users/underwriters/packages/TermsDetails.vue";

export default defineComponent({
  name: "cooperative-society-details",
  components: { GoBackButton, ClientClaimsTable, TermsDetails },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Details", ["Users", "Underwriters"]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab,
    };
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      admin: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
      },
      underwriter: {
        name: "",
        logo: "",
      },
      gender: {},
      cooperativeAdministrator: {},
      imageRoute: `${process.env.VUE_APP_BASE_URL}/logo/underwriter/`,
      avatarRoute: variables.AVATAR_ROUTE,
      policies: [],
      claims: [],
      additionalInformation: [{ rates: [] }],
    };
  },
  created() {
    // Get the cooperative information
    this.getUnderwriterInformation();

    this.displayDatatable();

    //Set page title
    document.title = "Underwriters Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getUnderwriterInformation() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.UNDERWRITERS_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to form fields
            this.admin = data.data.admin;
            this.underwriter = data.data.underwriter;
            this.additionalInformation = data.data.additionalInformation;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    displayDatatable() {
      // setTimeout(() => {
      if (this.policies) {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
        });
      }

      // }, 3000);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
